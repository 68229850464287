import type { Ref } from 'vue';
import { createPopper, type Modifier, type Placement } from '@popperjs/core';

interface Options {
    useSameWidth: boolean
    modifiers: Modifier<unknown, Record<string, unknown>>[]
}

export function usePopper(
    triggerNode: Ref<HTMLDivElement | undefined>,
    popperNode: Ref<HTMLDivElement | undefined>,
    alignment: string,
    options: Partial<Options> = {},
) {
    const popper = ref<ReturnType<typeof createPopper>>();

    const currentPlacement = ref('top');

    const watcher: Modifier<'placementWatcher', Record<string, unknown>> = {
        name: 'placementWatcher',
        enabled: true,
        phase: 'main',
        fn({ state }) {
            currentPlacement.value = state.placement.includes('top') ? 'top' : 'bottom';
        },
    };

    const sameWidth: Modifier<'sameWidth', Record<string, unknown>> = {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
        },

        effect: ({ state }) => {
            state.elements.popper.style.width = `${(state.elements.reference as HTMLDivElement).offsetWidth}px`;
        },
    };

    const finalOptions: Options = {
        useSameWidth: false,
        modifiers: [
            watcher,
        ],
        ...options,
    };

    if (finalOptions.useSameWidth) {
        finalOptions.modifiers.push(sameWidth);
    }

    onMounted(async () => {
        await nextTick();

        if (!triggerNode.value || !popperNode.value) {
            return;
        }

        let placement: Placement;
        let fallbackPlacements: Placement[];
        let allowedAutoPlacements: Placement[];

        switch (alignment) {
            case 'right': {
                placement = 'bottom-end';
                fallbackPlacements = ['top-end', 'bottom-end'];
                allowedAutoPlacements = ['top-end', 'bottom-end'];
                break;
            }
            case 'center':
            case 'center-bottom': {
                placement = 'bottom';
                fallbackPlacements = ['top', 'bottom'];
                allowedAutoPlacements = ['top', 'bottom'];
                break;
            }
            case 'center-top': {
                placement = 'top';
                fallbackPlacements = ['top', 'bottom'];
                allowedAutoPlacements = ['top', 'bottom'];
                break;
            }
            default: {
                placement = 'bottom-start';
                fallbackPlacements = ['top-start', 'bottom-start'];
                allowedAutoPlacements = ['top-start', 'bottom-start'];
            }
        }

        popper.value = createPopper(
            triggerNode.value,
            popperNode.value,
            {
                placement,
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements,
                            allowedAutoPlacements,
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 4],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            padding: 8,
                        },
                    },
                    ...finalOptions.modifiers,
                ],
            },
        );

        await popper.value.update();

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(1);
            }, 500);
        });

        await popper.value.update();
    });

    onBeforeUnmount(() => {
        popper.value?.destroy();
    });

    const updatePopper = async (timeout = 5000) => {
        setTimeout(async () => {
            await popper.value?.update();
        }, timeout);
    };

    return {
        triggerNode,
        popperNode,
        popper,
        placement: currentPlacement,
        updatePopper,
    };
}
